import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add.js';
import { getColumns } from './columns';
import { RowOverlayBox } from './style';
import { splitTestShape } from '../shapes';
import { filterModelShape, sortModelShape } from './shapes';
import {
  setSplitTestsListFilterModel,
  setSplitTestsListPage,
  setSplitTestsListPageSize,
  setSplitTestsListSortModel,
} from './action';
import { setSplitTestsCreateSplitTest } from '../CreateView/action';
import { setSplitTest } from '../Slug/action';

const SplitTestsList = ({
  dispatch,
  splitTests,
  gameSlug,
  invalidations,
  loading,
  sortModel,
  filterModel,
  pageSize,
  page,
}) => {
  useEffect(() => {
    dispatch(setSplitTestsCreateSplitTest(null));
    dispatch(setSplitTest(null));
  }, []);

  const columns = getColumns({ invalidations: invalidations[gameSlug] });

  // eslint-disable-next-line react/no-unstable-nested-components
  const Toolbar = () => (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button
        color="primary"
        component={NavLink}
        to={`/${gameSlug}/split-tests/new`}
        startIcon={<AddIcon />}
      >
        Add Split Test
      </Button>
      <div>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
      </div>
    </GridToolbarContainer>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const NoRowsOverlay = () => (
    <RowOverlayBox>
      <Alert severity="info">No split tests found!</Alert>
    </RowOverlayBox>
  );

  return (
    <Box>
      <h4>Split tests for {gameSlug}</h4>
      <DataGrid
        rows={splitTests || []}
        columns={columns}
        editMode="row"
        loading={loading}
        rowsPerPageOptions={[1, 5, 10, 20, 50, 100, 150, 200]}
        disableSelectionOnClick
        components={{
          Toolbar,
          NoRowsOverlay,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {
          dispatch(setSplitTestsListSortModel(newSortModel));
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {
          dispatch(setSplitTestsListFilterModel(newFilterModel));
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          dispatch(setSplitTestsListPage(0));
          dispatch(setSplitTestsListPageSize(newPageSize));
        }}
        page={page}
        onPageChange={(newPage) => {
          dispatch(setSplitTestsListPage(newPage));
        }}
        pagination
        autoHeight
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1,
          },
        }}
      />
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    gameSlug: state.gameApp.gameSlug,
    splitTests: state.splitTests.splitTests,
    loading: state.app.loading,
    invalidations: state.app.invalidations,
    sortModel: state.splitTestsLst.sortModel,
    filterModel: state.splitTestsLst.filterModel,
    pageSize: state.splitTestsLst.pageSize,
    page: state.splitTestsLst.page,
  };
}

SplitTestsList.defaultProps = {
  splitTests: [],
  gameSlug: null,
  invalidations: {},
  filterModel: undefined,
};

SplitTestsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  splitTests: PropTypes.arrayOf(splitTestShape),
  gameSlug: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  sortModel: sortModelShape.isRequired,
  filterModel: filterModelShape,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  invalidations: PropTypes.object,
};

export default connect(mapStateToProps)(SplitTestsList);
