import { GAME_APP_SET_GAME_SLUG } from './consts';

const initialState = {
  gameSlug: undefined,
};

export default (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case GAME_APP_SET_GAME_SLUG: {
      const { gameSlug } = action;
      return { ...state, gameSlug };
    }
    default:
      return state;
  }
};
