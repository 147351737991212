import { SPLIT_TESTS_SET_SPLIT_TESTS } from './consts';

const initialState = {
  splitTests: [],
};

export default (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case SPLIT_TESTS_SET_SPLIT_TESTS: {
      const { splitTests } = action;
      return {
        ...state,
        splitTests,
      };
    }
    default:
      return state;
  }
};
