import { SPLIT_TESTS_SLUG_SET_SPLIT_TEST } from './consts';

const initialState = {
  splitTest: null,
};

export default (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case SPLIT_TESTS_SLUG_SET_SPLIT_TEST: {
      const { splitTest } = action;
      return {
        ...state,
        splitTest,
      };
    }
    default:
      return state;
  }
};
