import {
  SPLIT_TESTS_LIST_SET_SORT_MODEL,
  SPLIT_TESTS_LIST_SET_FILTER_MODEL,
  SPLIT_TESTS_LIST_SET_PAGE_SIZE,
  SPLIT_TESTS_LIST_SET_PAGE,
} from './consts.js';

export function setSplitTestsListSortModel(sortModel) {
  return { type: SPLIT_TESTS_LIST_SET_SORT_MODEL, sortModel };
}

export function setSplitTestsListFilterModel(filterModel) {
  return { type: SPLIT_TESTS_LIST_SET_FILTER_MODEL, filterModel };
}

export function setSplitTestsListPageSize(pageSize) {
  return { type: SPLIT_TESTS_LIST_SET_PAGE_SIZE, pageSize };
}

export function setSplitTestsListPage(page) {
  return { type: SPLIT_TESTS_LIST_SET_PAGE, page };
}
