import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { loadSplitTestBySlug } from './action';

function SplitTestsSlug({ dispatch, gameSlug }) {
  const params = useParams();
  const navigate = useNavigate();
  const { splitTestSlug } = params;

  useEffect(() => {
    if (gameSlug && splitTestSlug) {
      dispatch(
        loadSplitTestBySlug({
          gameSlug,
          slug: splitTestSlug,
          navigate,
        })
      );
    }
  }, [gameSlug, splitTestSlug]);

  return <Outlet />;
}

function mapStateToProps(state) {
  return {
    gameSlug: state.gameApp.gameSlug,
  };
}

SplitTestsSlug.defaultProps = {
  gameSlug: null,
};

SplitTestsSlug.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gameSlug: PropTypes.string,
};

export default connect(mapStateToProps)(SplitTestsSlug);
