import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({ name = '...' }) {
  return (
    <Container>
      <Typography variant="h8" noWrap component="div">
        Loading {name}
      </Typography>
      <CircularProgress size={20} />
    </Container>
  );
}

Spinner.propTypes = {
  name: PropTypes.string,
};
