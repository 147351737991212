import { styled } from '@mui/system';

export const MainContainer = styled('div')(() => ({
  width: '100%',
  height: '50vh',
}));

export const FlexContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '5%',
}));
