import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

const DEFAULT_STYLE = {
  padding: { xs: 0, sm: 0 },
  position: 'absolute',
  left: 0,
  right: 0,
};

const xsHeight = 48;
const smHeight = 64;

function calculateValue(multiplier) {
  if (!multiplier) {
    return 0;
  }
  return { xs: `${xsHeight * multiplier}px`, sm: `${smHeight * multiplier}px` };
}

function getStyles({ bottom, top, size }) {
  const topValue = top === null ? undefined : calculateValue(top);
  const bottomValue = bottom === null ? undefined : calculateValue(bottom);
  const heightValue = size === null ? undefined : calculateValue(size);
  return {
    top: topValue,
    bottom: bottomValue,
    height: heightValue,
  };
}

export function FixContainer({ children, sx, bottom, top, size }) {
  return (
    <Container
      sx={{
        ...DEFAULT_STYLE,
        ...getStyles({ bottom, top, size }),
        ...sx,
      }}
    >
      {children}
    </Container>
  );
}

FixContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  bottom: PropTypes.number,
  top: PropTypes.number,
  size: PropTypes.number,
};

FixContainer.defaultProps = {
  sx: {},
  bottom: null,
  size: null,
  top: null,
};
