import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import { Search, SearchIconWrapper, StyledInputBase } from '../styles/menu';
import { FixContainer } from './FixContainer';
import { MenuEntry } from '../enums';

function filterGames(games, filterSubstring, setFilteredGames) {
  const allGames = games || [];
  const someGames = filterSubstring
    ? allGames.filter((game) => game.slug.toLowerCase().includes(filterSubstring.toLowerCase()))
    : allGames;
  setFilteredGames(someGames);
}

export default function Menu({ gameSlug, games, handleGameSlugChange, handlePageChange }) {
  const [filteredGames, setFilteredGames] = useState([]);
  const [filterSubstring, setFilterSubstring] = useState('');

  const currentMenu = gameSlug
    ? [
        <Button
          key="game-list-btn"
          onClick={() => {
            handleGameSlugChange(null);
          }}
        >
          <ArrowBackIosNewIcon />
          Games List
        </Button>,
        ...MenuEntry.map(({ name, path }) => (
          <ListItem button key={`${name}`} onClick={() => handlePageChange(path)}>
            <ListItemText primary={name} />
          </ListItem>
        )),
      ]
    : filteredGames.map(({ slug }) => (
        <ListItem
          button
          selected={slug === gameSlug}
          onClick={() => handleGameSlugChange(slug)}
          key={`game-${slug}`}
        >
          <ListItemText primary={slug} />
        </ListItem>
      ));

  useEffect(() => {
    if (games) {
      filterGames(games, filterSubstring, setFilteredGames);
    }
  }, [games, filterSubstring]);

  return (
    <>
      {!gameSlug && (
        <FixContainer top={0} size={1} sx={{ border: 1, borderColor: '#F5F5F5' }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event) => {
                setFilterSubstring(event.target.value);
              }}
              value={filterSubstring}
            />
          </Search>
        </FixContainer>
      )}
      <FixContainer top={gameSlug ? 0 : 1} bottom={0}>
        <List sx={{ padding: 0 }}>{currentMenu}</List>
      </FixContainer>
    </>
  );
}

Menu.defaultProps = {
  games: [],
  gameSlug: null,
};

Menu.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
    })
  ),
  gameSlug: PropTypes.string,
  handleGameSlugChange: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};
