import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { MainContainer } from '../../../../../../styles/common.js';
import { SplitTestAction, SplitTestStatus } from '../../../../../../enums';
import SplitTestForm from '../../../../../../components/SplitTestForm.js';
import Toolbar from '../../../../../../components/Toolbar';
import AlertDialog from '../../../../../../components/AlertDialog';
import SplitTestDistributionDetails from '../../../../../../components/SplitTestDistributionDetails';
import { updateSplitTest, executeDeleteSplitTest, clearAllDistributedUsers } from './action';
import { isEmptyObject } from '../../../../../../utils/validators';
import { splitTestShape } from '../../shapes';

const SplitTestsSlugDetails = ({ dispatch, splitTest, loading, invalidations }) => {
  const navigate = useNavigate();

  const [actionDialogStatus, setActionDialogStatus] = useState({
    isOpen: false,
  });

  const [invalidationInProgress, setInvalidationInProgress] = useState(false);

  const {
    slug,
    gameSlug,
    numberOfDistributions: oldNumberOfDistributions,
    usersPerDistribution: oldUsersPerDistribution,
    usersPerHour: oldUsersPerHour,
    status,
    currentDistributionStatus: {
      currentCount,
      distributionSpread = {},
      totalAmountOfUsersAllowed,
    } = {},
  } = splitTest || {};

  const isActive = status === SplitTestStatus.ACTIVE;
  const isInactive = status === SplitTestStatus.INACTIVE;

  const isDistributed = currentCount !== 0 && !isEmptyObject(distributionSpread);

  const handleConfirmationFlow = ({ actionName }) => {
    setActionDialogStatus({
      isOpen: true,
      splitTest,
      actionName,
    });
  };

  const handleConfirmationClose = () => {
    setActionDialogStatus({
      isOpen: false,
    });
  };

  const handleConfirmationAccepted = () => {
    const { actionName } = actionDialogStatus;
    handleConfirmationClose();
    if (actionName === SplitTestAction.DELETE) {
      dispatch(executeDeleteSplitTest({ gameSlug, slug, navigate }));
      return;
    }
    if (actionName === SplitTestAction.ACTIVATE) {
      dispatch(
        updateSplitTest({
          gameSlug,
          slug,
          patch: { status: SplitTestStatus.ACTIVE },
          navigate,
        })
      );
      return;
    }
    if (actionName === SplitTestAction.DEACTIVATE) {
      dispatch(
        updateSplitTest({
          gameSlug,
          slug,
          patch: { status: SplitTestStatus.INACTIVE },
          navigate,
        })
      );
    }
    if (actionName === SplitTestAction.RESET) {
      dispatch(
        clearAllDistributedUsers({
          gameSlug,
          slug,
          navigate,
        })
      );
    }
  };

  const onSubmit = ({ numberOfDistributions, usersPerDistribution, usersPerHour }) =>
    dispatch(
      updateSplitTest({
        gameSlug,
        slug,
        patch: {
          numberOfDistributions: numberOfDistributions || null,
          usersPerDistribution: usersPerDistribution || null,
          usersPerHour: usersPerHour || null,
        },
        navigate,
      })
    );

  const stateAction = [
    {
      icon: (
        <Tooltip title="Reset distributed users" arrow>
          <RotateLeftIcon
            color={invalidationInProgress || !isDistributed ? 'disabled' : 'primary'}
          />
        </Tooltip>
      ),
      clickHandler: () => handleConfirmationFlow({ actionName: SplitTestAction.RESET }),
      disabled: !!invalidationInProgress || !isDistributed,
    },
    isActive
      ? {
          icon: (
            <Tooltip title="Deactivate Split Test" arrow>
              <StopCircleIcon color={invalidationInProgress ? 'disabled' : 'error'} />
            </Tooltip>
          ),
          clickHandler: () => handleConfirmationFlow({ actionName: SplitTestAction.DEACTIVATE }),
          disabled: !!invalidationInProgress,
        }
      : {
          icon: (
            <Tooltip title="Activate Split Test" arrow>
              <PlayCircleFilledWhiteIcon color={invalidationInProgress ? 'disabled' : 'success'} />
            </Tooltip>
          ),
          clickHandler: () => handleConfirmationFlow({ actionName: SplitTestAction.ACTIVATE }),
          disabled: !!invalidationInProgress,
        },
    {
      icon: (
        <Tooltip title="Delete Split Test" arrow>
          <DeleteIcon color={isActive || invalidationInProgress ? 'disabled' : 'primary'} />
        </Tooltip>
      ),
      clickHandler: () => handleConfirmationFlow({ actionName: SplitTestAction.DELETE }),
      disabled: !!isActive || !!invalidationInProgress,
    },
  ];

  const determineInvalidationState = () => {
    if (!invalidations[gameSlug]) return false;
    return !!invalidations[gameSlug][slug];
  };

  useEffect(() => {
    setInvalidationInProgress(determineInvalidationState());
  }, [invalidations]);

  return (
    <MainContainer>
      <Toolbar
        title={`Details of split test "${slug}" for game "${gameSlug}"`}
        actions={stateAction}
        invalidation={!!invalidationInProgress}
      />
      <SplitTestForm
        onSubmit={onSubmit}
        gameSlug={gameSlug}
        disabled={loading || isActive || isInactive}
        slug={slug}
        numberOfDistributions={oldNumberOfDistributions}
        usersPerDistribution={oldUsersPerDistribution}
        usersPerHour={oldUsersPerHour}
        isUpdate
      />
      <Typography color="primary" noWrap sx={{ flexGrow: 1, fontWeight: 'bold' }}>
        Distributed users: {currentCount} / {totalAmountOfUsersAllowed}
      </Typography>
      {currentCount !== 0 && (
        <SplitTestDistributionDetails distributionSpread={distributionSpread} />
      )}
      {actionDialogStatus.isOpen ? (
        <AlertDialog
          open={actionDialogStatus.isOpen}
          handleClose={handleConfirmationClose}
          dispatchActions={handleConfirmationAccepted}
          actionName={actionDialogStatus.actionName}
          splitTest={actionDialogStatus.splitTest}
        />
      ) : (
        ''
      )}
    </MainContainer>
  );
};

function mapStateToProps(state) {
  return {
    splitTest: state.splitTestsSlug.splitTest,
    loading: state.app.loading,
    invalidations: state.app.invalidations,
  };
}

SplitTestsSlugDetails.defaultProps = {
  splitTest: null,
  invalidations: {},
};

SplitTestsSlugDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  splitTest: splitTestShape,
  loading: PropTypes.bool.isRequired,
  invalidations: PropTypes.object,
};

export default connect(mapStateToProps)(SplitTestsSlugDetails);
