import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { setGameSlug } from './action';
import { addMessage } from '../action';
import { Severity } from '../../../enums';
import { getLog } from '../../../utils/log';

const log = getLog('GameApp');

function GameApp({ gameSlug, games, dispatch }) {
  const { gameSlug: newGameSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!gameSlug) {
      log.debug('No gameSlug defined yet');
    }
    if (games && gameSlug !== newGameSlug) {
      log.debug('Trying to set gameSlug');
      if (games.find(({ slug }) => slug === newGameSlug)) {
        log.debug('Setting gameSlug');
        dispatch(setGameSlug(newGameSlug));
      } else {
        log.error(`Could not find game with gameSlug "${newGameSlug}"`);
        dispatch(
          addMessage('InvalidGameSlug', Severity.ERROR, `No game found with slug "${newGameSlug}"`)
        );
        dispatch(setGameSlug(null));
        navigate('/');
      }
    } else {
      log.debug('No games yet');
    }
  }, [games]);

  return <Outlet />;
}

function mapStateToProps(state) {
  return {
    gameSlug: state.gameApp.gameSlug,
    games: state.app.games,
  };
}

GameApp.defaultProps = {
  gameSlug: null,
  games: null,
};

GameApp.propTypes = {
  gameSlug: PropTypes.string,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(GameApp);
