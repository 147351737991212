import PropTypes from 'prop-types';

export const splitTestShape = PropTypes.shape({
  slug: PropTypes.string,
  usersPerDistribution: PropTypes.number,
  numberOfDistributions: PropTypes.number,
  usersPerHour: PropTypes.number,
  currentDistributionStatus: PropTypes.shape({
    currentCount: PropTypes.number,
    totalAmountOfUsersAllowed: PropTypes.number,
  }),
  status: PropTypes.string,
});
