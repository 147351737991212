import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app from '../../containers/App/reducer';
import gameApp from '../../containers/App/GameApp/reducer';
import splitTests from '../../containers/App/GameApp/SplitTests/reducer';
import splitTestsCreate from '../../containers/App/GameApp/SplitTests/CreateView/reducer';
import splitTestsLst from '../../containers/App/GameApp/SplitTests/ListView/reducer';
import splitTestsSlug from '../../containers/App/GameApp/SplitTests/Slug/reducer';

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: ['themeMode', 'invalidations'],
};

const splitTestsLstPersistConfig = {
  key: 'splitTestsLst',
  storage,
};

export default combineReducers({
  app: persistReducer(appPersistConfig, app),
  gameApp,
  splitTests,
  splitTestsCreate,
  splitTestsLst: persistReducer(splitTestsLstPersistConfig, splitTestsLst),
  splitTestsSlug,
});
