import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { loadSplitTests } from './action';
import { setSplitTestsCreateSplitTest } from './CreateView/action';

function SplitTests({ dispatch, gameSlug }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (gameSlug) {
      dispatch(setSplitTestsCreateSplitTest(null));
      dispatch(loadSplitTests({ gameSlug, navigate }));
    }
  }, [gameSlug]);

  return <Outlet />;
}

function mapStateToProps(state) {
  return {
    gameSlug: state.gameApp.gameSlug,
  };
}

SplitTests.defaultProps = {
  gameSlug: null,
};

SplitTests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  gameSlug: PropTypes.string,
};

export default connect(mapStateToProps)(SplitTests);
