import React from 'react';
import { Route, Routes } from 'react-router-dom';

import GameApp from './GameApp';
import SplitTests from './GameApp/SplitTests';
import SplitTestsList from './GameApp/SplitTests/ListView';
import SplitTestsCreate from './GameApp/SplitTests/CreateView';
import SplitTestsSlug from './GameApp/SplitTests/Slug';
import SplitTestsSlugDetails from './GameApp/SplitTests/Slug/DetailsView';

export function getRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<div>Please select a game</div>} />
      <Route path=":gameSlug" element={<GameApp />}>
        <Route path="split-tests" element={<SplitTests />}>
          <Route index element={<SplitTestsList />} />
          <Route path="new" element={<SplitTestsCreate />} />
          <Route path=":splitTestSlug" element={<SplitTestsSlug />}>
            <Route index element={<SplitTestsSlugDetails />} />
          </Route>
          <Route path="*" element={<div>Unknown split test route</div>} />
        </Route>
        <Route path="*" element={<div>Unknown game route</div>} />
      </Route>
      <Route path="*" element={<div>Unknown route</div>} />
    </Routes>
  );
}
