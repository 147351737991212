import {
  APP_SET_THEME_MODE,
  APP_SET_USER,
  APP_ADD_MESSAGE,
  APP_DELETE_MESSAGE,
  APP_SET_GAMES,
  APP_SET_LOADING,
  APP_SET_ERROR,
  APP_ADD_INVALIDATION,
  APP_DELETE_INVALIDATION,
} from './consts';
import { Theme } from '../../enums';

const initialState = {
  themeMode: Theme.DARK,
  messages: [],
  loading: false,
  games: null,
  error: null,
};

export default (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case APP_SET_USER: {
      const { user } = action;
      return { ...state, user };
    }
    case APP_SET_THEME_MODE: {
      const { mode } = action;
      return { ...state, themeMode: mode };
    }
    case APP_ADD_MESSAGE: {
      const { messageId, severity, text } = action;
      const { messages } = state;
      return {
        ...state,
        messages: [
          ...messages,
          {
            messageId,
            severity,
            text,
          },
        ],
      };
    }
    case APP_DELETE_MESSAGE: {
      const { messageId } = action;
      const { messages } = state;
      return { ...state, messages: messages.filter(({ messageId: id }) => id !== messageId) };
    }
    case APP_SET_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }
    case APP_SET_GAMES: {
      const { games } = action;
      return {
        ...state,
        games,
      };
    }
    case APP_SET_LOADING: {
      const { status } = action;
      return {
        ...state,
        loading: status,
        error: status ? null : state.error,
      };
    }
    case APP_ADD_INVALIDATION: {
      const { invalidationId, slug, gameSlug } = action;
      const { invalidations } = state;
      return {
        ...state,
        invalidations: {
          ...invalidations,
          [gameSlug]: {
            ...invalidations[gameSlug],
            [slug]: {
              invalidationId,
            },
          },
        },
      };
    }
    case APP_DELETE_INVALIDATION: {
      const { invalidationId, slug, gameSlug } = action;
      const { invalidations } = state;
      const newInvalidations = { ...invalidations };
      const gameInvalidations = { ...newInvalidations[gameSlug] };
      const { invalidationId: currentInvalidationId } = gameInvalidations[slug] || {};
      if (currentInvalidationId === invalidationId) {
        delete gameInvalidations[slug];
        if (Object.keys(gameInvalidations).length === 0) {
          delete newInvalidations[gameSlug];
        } else {
          newInvalidations[gameSlug] = gameInvalidations;
        }
      }

      return { ...state, invalidations: newInvalidations };
    }
    default:
      return state;
  }
};
