import { SPLIT_TESTS_SET_SPLIT_TESTS } from './consts';
import { getSplitTests } from '../../../../connections/st-api';
import { addMessage, setLoading } from '../../action';
import { getLog } from '../../../../utils/log';
import { Severity } from '../../../../enums';
import { setGameSlug } from '../action';

const log = getLog('SplitTests:action');

export function setSplitTests(splitTests) {
  return { type: SPLIT_TESTS_SET_SPLIT_TESTS, splitTests };
}

export function loadSplitTests({ gameSlug, navigate }) {
  const messageId = `${gameSlug}-split-tests-load`;
  return (dispatch) => {
    dispatch(setLoading(true));
    getSplitTests(gameSlug)
      .then((splitTests) => {
        dispatch(setSplitTests(splitTests));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        log.error('Loading split tests failed', error);
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.ERROR,
            `Failed to load Split Tests for game ${gameSlug}. Error message: ${error.message}`
          )
        );
        dispatch(setGameSlug(null));
        navigate('/');
      });
  };
}
