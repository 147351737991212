import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { createSplitTest } from './action';
import SplitTestForm from '../../../../../components/SplitTestForm.js';
import { splitTestShape } from '../shapes';

const SplitTestsCreate = ({ dispatch, gameSlug, splitTest, loading }) => {
  const navigate = useNavigate();

  const onSubmit = ({ slug, numberOfDistributions, usersPerDistribution, usersPerHour }) =>
    dispatch(
      createSplitTest({
        newSplitTest: {
          gameSlug,
          slug,
          numberOfDistributions: numberOfDistributions || undefined,
          usersPerDistribution: usersPerDistribution || undefined,
          usersPerHour: usersPerHour || undefined,
        },
        navigate,
      })
    );

  return (
    <Container fixed>
      <h4>Create split test for {gameSlug}</h4>
      <SplitTestForm
        onSubmit={onSubmit}
        gameSlug={gameSlug}
        disabled={loading}
        enableEdit={!!splitTest}
        slug={splitTest ? splitTest.slug : ''}
        numberOfDistributions={splitTest ? splitTest.numberOfDistributions : undefined}
        usersPerDistribution={splitTest ? splitTest.usersPerDistribution : undefined}
        usersPerHour={splitTest ? splitTest.usersPerHour : undefined}
        isUpdate={false}
      />
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    gameSlug: state.gameApp.gameSlug,
    splitTest: state.splitTestsCreate.splitTest,
    loading: state.app.loading,
  };
}

SplitTestsCreate.defaultProps = {
  gameSlug: null,
  splitTest: null,
};

SplitTestsCreate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  splitTest: splitTestShape,
  gameSlug: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(SplitTestsCreate);
