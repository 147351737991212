import { SPLIT_TESTS_CREATE_SET_SPLIT_TEST } from './consts.js';
import { postSplitTest } from '../../../../../connections/st-api';
import { addMessage, setLoading } from '../../../action';
import { Severity } from '../../../../../enums';
import { loadSplitTests } from '../action';

export function setSplitTestsCreateSplitTest(splitTest) {
  return { type: SPLIT_TESTS_CREATE_SET_SPLIT_TEST, splitTest };
}

export function createSplitTest({ newSplitTest, navigate }) {
  const { slug, gameSlug } = newSplitTest;
  const messageId = `${gameSlug}-${slug}-split-test-create`;
  return (dispatch) => {
    dispatch(setLoading(true));
    postSplitTest(newSplitTest)
      .then((splitTest) => {
        dispatch(setSplitTestsCreateSplitTest(splitTest));
        dispatch(setLoading(false));
        dispatch(loadSplitTests({ gameSlug, navigate }));
        dispatch(
          addMessage(messageId, Severity.SUCCESS, `Created Split Test ${slug} for game ${gameSlug}`)
        );
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.ERROR,
            `Failed to create Split Test ${slug} for game ${gameSlug}. Error message: ${error.message}`
          )
        );
      });
  };
}
