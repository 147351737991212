import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, Switch } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import {
  LoginButtonPlaceholder,
  useGoogleIdentity,
  googleUserShape,
  LogoutReason,
} from '@softgames/use-google-identity';
import Layout from '../../components/Layout';
import Menu from '../../components/Menu';
import { Severity, Theme } from '../../enums';
import {
  checkInvalidationStatus,
  addMessage,
  loadGames,
  removeMessage,
  setError,
  setThemeMode,
  setUser,
} from './action';
import { messagesShape } from '../../components/Messages';
import { getRoutes } from './routes';
import { isEmptyObject } from '../../utils/validators';
import { setGameSlug } from './GameApp/action';
import { CenterBox } from '../../components/CenterBox';
import { getLog } from '../../utils/log';

const { REACT_APP_ST_GOOGLE_CLIENT_ID } = process.env;
const log = getLog('app');

function App({
  dispatch,
  themeMode,
  invalidations,
  messages,
  games,
  loading,
  error,
  user,
  gameSlug,
}) {
  const navigate = useNavigate();
  const {
    logout,
    user: googleUser,
    logoutReason,
    error: googleError,
  } = useGoogleIdentity({
    clientId: REACT_APP_ST_GOOGLE_CLIENT_ID,
    sisAuthClientSlug: 'SplitTestAdmin',
  });

  useEffect(() => {
    if (googleUser) {
      const { firstName } = googleUser;
      dispatch(setUser(googleUser));
      dispatch(addMessage('login-1658178897', Severity.SUCCESS, `${firstName} logged in`));
      return;
    }
    if (!user) return;
    if (logoutReason === LogoutReason.Manual) {
      const { firstName } = user;
      dispatch(addMessage('logout-1658487562', Severity.SUCCESS, `${firstName} logged out`));
    }
    dispatch(setUser(null));
  }, [googleUser, logoutReason]);

  useEffect(() => {
    if (googleError) {
      log.error('Google auth error', {
        error: googleError.toObject(),
      });
      dispatch(setError(googleError));
    }
  }, [googleError]);

  useEffect(() => {
    if (user) {
      if (!games) {
        dispatch(loadGames());
      }
    }
  }, [user, games]);

  useEffect(() => {
    if (!isEmptyObject(invalidations)) {
      Object.entries(invalidations).forEach(([invalidationGameSlug, gameInvalidations]) => {
        Object.entries(gameInvalidations).forEach(([slug, { invalidationId }]) => {
          dispatch(
            checkInvalidationStatus({ invalidationId, gameSlug: invalidationGameSlug, slug })
          );
        });
      });
    }
  }, []);

  const handleGameSlugChange = (newGameSlug) => {
    if (newGameSlug) {
      navigate(`/${newGameSlug}/split-tests`);
    } else {
      dispatch(setGameSlug(newGameSlug));
      navigate('/');
    }
  };

  const handlePageChange = (path) => {
    navigate(`/${gameSlug}/${path}`);
  };

  const settings = getSettings({ themeMode, dispatch });
  const { menu, content } = getPageComponents({
    error,
    user,
    gameSlug,
    games,
    loading,
    handleGameSlugChange,
    handlePageChange,
  });
  return (
    <Layout
      title="Split Test Admin"
      menu={menu}
      settings={settings}
      themeMode={themeMode}
      messages={messages}
      user={user}
      signOut={logout}
      onMessageClose={(messageId) => {
        dispatch(removeMessage(messageId));
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!loading || !!error || false}
      >
        {getBackdropContent({ loading, error })}
      </Backdrop>
      {content}
    </Layout>
  );
}

function getSettings({ themeMode, dispatch }) {
  return [
    <FormControlLabel
      key="settings-themeMode"
      control={
        <Switch
          checked={themeMode === Theme.DARK}
          onChange={(event, checked) => {
            dispatch(setThemeMode(checked ? Theme.DARK : Theme.LIGHT));
          }}
        />
      }
      label="Dark Mode"
      labelPlacement="top"
    />,
  ];
}

function getPageComponents({ user, gameSlug, games, handleGameSlugChange, handlePageChange }) {
  if (!user) {
    return {
      content: (
        <CenterBox>
          <LoginButtonPlaceholder />
        </CenterBox>
      ),
    };
  }
  return {
    menu: (
      <Menu
        gameSlug={gameSlug}
        games={games}
        handleGameSlugChange={handleGameSlugChange}
        handlePageChange={handlePageChange}
      />
    ),
    content: getRoutes(),
  };
}

function getBackdropContent({ loading, error }) {
  if (loading) {
    return <CircularProgress color="inherit" />;
  }
  if (error) {
    return <Alert severity="error">Failed {error.message || error}</Alert>;
  }
  return null;
}

function mapStateToProps(state) {
  return {
    themeMode: state.app.themeMode,
    messages: state.app.messages,
    loading: state.app.loading,
    gameSlug: state.gameApp.gameSlug,
    invalidations: state.app.invalidations,
    games: state.app.games,
    error: state.app.error,
    user: state.app.user,
  };
}

App.defaultProps = {
  gameSlug: null,
  invalidations: {},
  games: null,
  error: null,
  user: null,
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  themeMode: PropTypes.oneOf(Object.values(Theme)).isRequired,
  messages: PropTypes.arrayOf(messagesShape).isRequired,
  loading: PropTypes.bool.isRequired,
  gameSlug: PropTypes.string,
  invalidations: PropTypes.object,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
    })
  ),
  error: PropTypes.instanceOf(Error),
  user: googleUserShape,
};

export default connect(mapStateToProps)(App);
