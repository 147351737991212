import { SPLIT_TESTS_SLUG_SET_SPLIT_TEST } from './consts';
import { getSplitTest } from '../../../../../connections/st-api';
import { getLog } from '../../../../../utils/log';
import { addMessage, setLoading } from '../../../action';
import { Severity } from '../../../../../enums';

const log = getLog('MessageTemplateVersion:Slug:action');

export function setSplitTest(splitTest) {
  return { type: SPLIT_TESTS_SLUG_SET_SPLIT_TEST, splitTest };
}

export function loadSplitTestBySlug({ gameSlug, slug, navigate }) {
  const messageId = `${gameSlug}-${slug}-split-test-load-by-slug`;
  return (dispatch) => {
    setLoading(true);
    getSplitTest({ gameSlug, slug })
      .then((splitTest) => {
        dispatch(setSplitTest(splitTest));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        log.error(`Loading split test failed "${slug}"`, error);
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.ERROR,
            `Could not load Split Test ${slug} for game ${gameSlug}`
          )
        );
        navigate(`/${gameSlug}/split-tests`);
      });
  };
}
