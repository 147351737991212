import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import App from './containers/App';
import { prepareStore } from './utils/store';
import Spinner from './components/Spinner';

const { store, persistor } = prepareStore();

const render = () => {
  // eslint-disable-next-line no-undef
  const target = document.getElementById('root');
  const root = createRoot(target);
  root.render(
    <Provider store={store}>
      <PersistGate loading={<Spinner name="State" />} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

render();
