import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { properLinks } from '../enums';

const toTitleCase = (str, properLink) =>
  properLink
    ? str.replace(/\b\w+/g, (s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
    : str;

export default function HeaderBreadcrumbs({ title }) {
  const location = useLocation();
  const navigate = useNavigate();
  const pathNames = location.pathname.split('/').filter((path) => path);

  const handlePathChange = (path, isProperLink) => {
    if (isProperLink) {
      navigate(path);
    }
  };

  return (
    <div role="presentation">
      <Breadcrumbs
        separator={<NavigateNextIcon color="inherit" fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link href="/" underline="hover" color="#F5F5F5" variant="contained">
          {title}
        </Link>
        {pathNames.map((value, index) => {
          const last = index === pathNames.length - 1;
          const path = `/${pathNames.slice(0, index + 1).join('/')}`;
          const lastPathSegment = path.substring(path.lastIndexOf('/') + 1);
          const isProperLink = properLinks.includes(lastPathSegment);
          return last ? (
            <Link
              color="textPrimary"
              to={path}
              key={path}
              underline="none"
              sx={{ cursor: 'default' }}
            >
              {toTitleCase(value, isProperLink)}
            </Link>
          ) : (
            <Link
              underline={isProperLink ? 'hover' : 'none'}
              onClick={() => {
                handlePathChange(path, isProperLink);
              }}
              to={path}
              key={path}
              disabled
              sx={{ cursor: isProperLink ? 'pointer' : 'default' }}
              color={isProperLink ? '#F5F5F5' : 'textPrimary'}
            >
              {toTitleCase(value, isProperLink)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
HeaderBreadcrumbs.defaultProps = {
  title: null,
};
HeaderBreadcrumbs.propTypes = {
  title: PropTypes.string,
};
