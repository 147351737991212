import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FlexContainer } from '../styles/common.js';

export default function Toolbar({ title, actions, invalidation }) {
  return (
    <FlexContainer>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
      {invalidation && (
        <>
          <Box component="span" sx={{ p: 1 }}>
            <CircularProgress size={20} />
          </Box>

          <Typography
            variant="caption"
            display="block"
            component="div"
            sx={{ flexGrow: 1 }}
            color="primary"
          >
            Actions disabled during Invalidation process
          </Typography>
        </>
      )}
      {actions.map(({ icon, clickHandler, disabled }, index) => (
        <IconButton
          key={`action-button-${index}`}
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={clickHandler}
          disabled={disabled}
        >
          {icon}
        </IconButton>
      ))}
    </FlexContainer>
  );
}

Toolbar.propTypes = {
  title: PropTypes.string,
  invalidation: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.element,
      clickHandler: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
};
