import { init, getLogger, SEVERITY } from '@softgames/fe-logger';
import { isTruthy } from './validators';

const { REACT_APP_ENABLE_LOG } = process.env;

init({
  name: 'st-admin',
  severity: isTruthy(REACT_APP_ENABLE_LOG) ? SEVERITY.DEBUG : SEVERITY.DISABLE,
});

export function getLog(name) {
  return getLogger({ name });
}
