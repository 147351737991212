import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormGroup from '@mui/material/FormGroup';
import { LogoutButton, googleUserShape } from '@softgames/use-google-identity';
import { AppBar } from './AppBar';
import { Drawer } from './Drawer';
import { Theme } from '../../enums';
import { FixContainer } from '../FixContainer';
import Messages, { messagesShape } from '../Messages';
import HeaderBreadcrumbs from '../Breadcrumbs';

const prepareTheme = (themeMode) =>
  createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: '#f5842d',
        light: '#ffbfa2',
        dark: '#ff4e00',
      },
      secondary: {
        main: '#2d5a6b',
        light: '#a5cbd9',
        dark: '#1e3d48',
      },
    },
  });

export default function Layout({
  title,
  menu,
  user,
  signOut,
  settings,
  children,
  themeMode,
  messages,
  onMessageClose,
}) {
  const [baseTheme, setBaseTheme] = useState(prepareTheme(Theme.DARK));
  const [open, setOpen] = useState(!!menu);

  useEffect(() => {
    if (menu) {
      if (!open) {
        setOpen(true);
      }
    } else if (open) {
      setOpen(false);
    }
  }, [menu]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setBaseTheme(prepareTheme(themeMode));
  }, [themeMode]);

  return (
    <ThemeProvider theme={baseTheme}>
      <SnackbarProvider maxSnack={3}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <HeaderBreadcrumbs title={title} />
              {user ? <LogoutButton user={user} signOut={signOut} /> : ''}
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open} sx={{ position: 'relative', height: '100vh' }}>
            <FixContainer top={0} size={1}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
            </FixContainer>
            <FixContainer top={1} bottom={settings.length}>
              {menu || ''}
            </FixContainer>
            <FixContainer bottom={0} size={settings.length}>
              <Divider />
              <FormGroup sx={{ width: '100%' }}>{settings.map((entry) => entry)}</FormGroup>
            </FixContainer>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>{children}</Paper>
                </Grid>
              </Grid>
            </Container>
            <Messages messages={messages} onClose={onMessageClose} />
          </Box>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

Layout.defaultProps = {
  menu: null,
  user: null,
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  settings: PropTypes.arrayOf(PropTypes.element).isRequired,
  themeMode: PropTypes.oneOf(Object.values(Theme)).isRequired,
  messages: PropTypes.arrayOf(messagesShape).isRequired,
  onMessageClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  signOut: PropTypes.func.isRequired,
  menu: PropTypes.element,
  user: googleUserShape,
};
