import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Severity } from '../enums';

export default function Messages({ messages, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    messages.forEach(({ messageId, text, severity }) => {
      enqueueSnackbar(text, {
        variant: severity,
        autoHideDuration: severity === Severity.ERROR ? 15000 : 5000,
        onClose: () => {
          onClose(messageId);
        },
        preventDuplicate: true,
      });
    });
  }, [messages]);

  return null;
}
export const messagesShape = PropTypes.shape({
  messageId: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(Object.values(Severity)).isRequired,
  text: PropTypes.string.isRequired,
});

Messages.propTypes = {
  onClose: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(messagesShape).isRequired,
};
