import { persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';
import configureStore from './configureStore';

export function prepareStore() {
  const history = createBrowserHistory();
  const store = configureStore(history);
  const persistor = persistStore(store);
  return { store, persistor };
}
