import {
  patchSplitTest,
  deleteSplitTest,
  resetDistributedUsers,
} from '../../../../../../connections/st-api';
import { addMessage, checkInvalidationStatus, setLoading } from '../../../../action';
import { Severity } from '../../../../../../enums';
import { hasOwnProperty } from '../../../../../../utils/validators';
import { loadSplitTestBySlug, setSplitTest } from '../action';
import { loadSplitTests } from '../../action';
import { getLog } from '../../../../../../utils/log';

const log = getLog('SplitTests:DetailsView:actions');

export function updateSplitTest({ gameSlug, slug, patch, navigate }) {
  const messageId = `${gameSlug}-${slug}-details-update`;
  return (dispatch) => {
    dispatch(setLoading(true));
    patchSplitTest({ gameSlug, slug, patch })
      .then((updatedSplitTest) => {
        dispatch(setSplitTest(updatedSplitTest));
        dispatch(
          addMessage(
            messageId,
            Severity.SUCCESS,
            `Split Test ${slug} for game ${gameSlug} is updated`
          )
        );
        dispatch(setLoading(false));
        dispatch(loadSplitTests({ gameSlug, navigate }));
        /* eslint-disable no-return-assign, no-param-reassign */
        if (hasOwnProperty(updatedSplitTest, 'invalidationId')) {
          dispatch(
            checkInvalidationStatus({
              invalidationId: updatedSplitTest.invalidationId,
              gameSlug,
              slug,
            })
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        log.error(error);
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.ERROR,
            `Failed to update Split Test ${slug} for game ${gameSlug}. Error message: ${error.message}`
          )
        );
        /* eslint-enable no-return-assign, no-param-reassign */
      });
  };
}

export function executeDeleteSplitTest({ gameSlug, slug, navigate }) {
  const messageId = `${gameSlug}-${slug}-details-delete`;
  return (dispatch) => {
    dispatch(setLoading(true));
    deleteSplitTest({ gameSlug, slug })
      .then(() => {
        dispatch(
          addMessage(
            messageId,
            Severity.SUCCESS,
            `Split Test ${slug} for game ${gameSlug} has been deleted`
          )
        );
        dispatch(setLoading(false));
        dispatch(loadSplitTests({ gameSlug, navigate }));
        navigate(`/${gameSlug}/split-tests`);
      })
      .catch((error) => {
        log.error(error);
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.ERROR,
            `Failed to delete Split Test ${slug} for game ${gameSlug}. Error message: ${error.message}`
          )
        );
      });
  };
}

export function clearAllDistributedUsers({ gameSlug, slug, navigate }) {
  const messageId = `${gameSlug}-${slug}-details-delete-distributions`;
  return (dispatch) => {
    dispatch(setLoading(true));
    resetDistributedUsers({ slug, gameSlug })
      .then((data) => {
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.SUCCESS,
            `Reset distributed users for Split Test ${slug} for game ${gameSlug} is done`
          )
        );
        dispatch(loadSplitTestBySlug({ gameSlug, slug, navigate }));
        dispatch(loadSplitTests({ gameSlug, navigate }));
        /* eslint-disable no-return-assign, no-param-reassign */
        if (hasOwnProperty(data, 'invalidationId')) {
          dispatch(
            checkInvalidationStatus({
              invalidationId: data.invalidationId,
              gameSlug,
              slug,
            })
          );
        }
      })
      .catch((error) => {
        log.error(error);
        dispatch(setLoading(false));
        dispatch(
          addMessage(
            messageId,
            Severity.ERROR,
            `Failed to reset distributed users for Split Test ${slug} for game ${gameSlug}. Error message: ${error.message}`
          )
        );
        /* eslint-enable no-return-assign, no-param-reassign */
      });
  };
}
