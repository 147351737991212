import { executeHttpRequest } from '@softgames/use-google-identity';

const { REACT_APP_SIS_API } = process.env;

export const getSplitTestGames = async () => {
  const {
    data: { splitTests },
  } = await executeHttpRequest({
    url: REACT_APP_SIS_API,
    method: 'post',
    body: {
      query: `
        query getSplitTestGames($enabled: Boolean!) {
          splitTests(enabled: $enabled) {
            gameSlug
          }
        }
    `,
      variables: {
        enabled: true,
      },
      useCache: false,
    },
  });
  return splitTests.map(({ gameSlug: slug }) => ({ slug }));
};
