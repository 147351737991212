import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
/* eslint react/no-array-index-key:0 */

const getDistributionColumns = (data) =>
  Object.entries(data).map(([key, value]) => (
    <TableRow key={key}>
      <TableCell>{key}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  ));

export default function SplitTestDistributionDetails({ distributionSpread }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Distribution Id</TableCell>
            <TableCell>Distributed Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{getDistributionColumns(distributionSpread)}</TableBody>
      </Table>
    </TableContainer>
  );
}

SplitTestDistributionDetails.propTypes = {
  distributionSpread: PropTypes.object,
};
