import { styled } from '@mui/system';

export const CenterBox = styled('div')({
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '30%',
  marginTop: '20px',
  marginBottom: '20px',
  minWidth: '350px',
});
