import { SPLIT_TESTS_CREATE_SET_SPLIT_TEST } from './consts.js';

const createInitialState = {
  splitTest: null,
};

export default (state = createInitialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case SPLIT_TESTS_CREATE_SET_SPLIT_TEST: {
      const { splitTest } = action;
      return {
        ...state,
        splitTest,
      };
    }
    default:
      return state;
  }
};
