import PropTypes from 'prop-types';
import { GridLinkOperator } from '@mui/x-data-grid';

const sortModelEntry = PropTypes.shape({
  field: PropTypes.string.isRequired,
  sort: PropTypes.oneOf([undefined, null, 'asc', 'desc']),
});

export const sortModelShape = PropTypes.oneOfType([
  sortModelEntry,
  PropTypes.arrayOf(sortModelEntry),
]);

const filterModelItem = PropTypes.shape({
  id: PropTypes.number,
  columnField: PropTypes.string.isRequired,
  operatorValue: PropTypes.string,
  value: PropTypes.string,
});

const filterModelOperator = PropTypes.oneOf([GridLinkOperator.Or, GridLinkOperator.And]);

const filterModelEntry = PropTypes.shape({
  items: PropTypes.arrayOf(filterModelItem).isRequired,
  linkOperator: filterModelOperator,
  quickFilterLogicOperator: filterModelOperator,
  quickFilterValues: PropTypes.arrayOf(PropTypes.string),
});

export const filterModelShape = PropTypes.oneOfType([
  filterModelEntry,
  PropTypes.arrayOf(filterModelEntry),
]);
