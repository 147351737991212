import React from 'react';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { isNonNegativeIntegerLike } from '../../../../../utils/validators';

const DATE_VALUE = /\d/;

const positiveNumberFilter = (value) => {
  if (!isNonNegativeIntegerLike(value)) {
    return null;
  }
  return ({ value: fieldValue }) => {
    return Number(fieldValue) === Number(value);
  };
};

export function getColumns({ invalidations = {} }) {
  return [
    {
      field: 'slug',
      headerName: 'Split Test Slug',
      width: 200,
      editable: false,
      getApplyQuickFilterFn: (value) => {
        return ({ value: fieldValue }) => {
          return fieldValue && fieldValue.includes(value);
        };
      },
      disableColumnMenu: true,
    },
    {
      field: 'usersPerDistribution',
      headerName: 'Users Per Distribution',
      width: 120,
      editable: false,
      getApplyQuickFilterFn: positiveNumberFilter,
      disableColumnMenu: true,
    },
    {
      field: 'numberOfDistributions',
      headerName: 'Number of Distributions',
      width: 120,
      editable: false,
      getApplyQuickFilterFn: positiveNumberFilter,
      disableColumnMenu: true,
    },
    {
      field: 'usersPerHour',
      headerName: 'Max. number of users distributed per hour',
      width: 140,
      editable: false,
      getApplyQuickFilterFn: positiveNumberFilter,
      disableColumnMenu: true,
    },
    {
      field: 'distributedUsers',
      headerName: 'Distributed Users',
      width: 150,
      editable: false,
      renderCell: ({
        row: {
          currentDistributionStatus: { currentCount, totalAmountOfUsersAllowed },
        },
      }) => `${currentCount} / ${totalAmountOfUsersAllowed}`,
      filterable: false,
      getApplyQuickFilterFn: undefined,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      getApplyQuickFilterFn: (value) => {
        return ({ value: fieldValue }) => {
          return fieldValue && fieldValue.toUpperCase().includes(value.toUpperCase());
        };
      },
      disableColumnMenu: true,
    },
    {
      field: 'creationDate',
      headerName: 'Created at',
      width: 150,
      editable: false,
      renderCell: ({ row: { creationDate } }) => creationDate.toLocaleString(),
      getApplyQuickFilterFn: (value) => {
        if (!DATE_VALUE.test(value)) {
          return null;
        }
        return ({ value: fieldValue }) => {
          return fieldValue.toISOString().includes(value);
        };
      },
      disableColumnMenu: true,
    },
    {
      field: 'details',
      headerName: 'Details',
      sortable: false,
      editable: false,
      width: 100,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: ({ row: { gameSlug, slug } }) => {
        return (
          <>
            <Button
              color="primary"
              component={NavLink}
              to={`/${gameSlug}/split-tests/${slug}`}
              startIcon={<VisibilityIcon color="primary" />}
            />
            {invalidations[slug] && (
              <Tooltip title="Invalidation is running" arrow>
                <CircularProgress size={15} />
              </Tooltip>
            )}
          </>
        );
      },
      getApplyQuickFilterFn: undefined,
    },
  ];
}
