import {
  SPLIT_TESTS_LIST_SET_SORT_MODEL,
  SPLIT_TESTS_LIST_SET_FILTER_MODEL,
  SPLIT_TESTS_LIST_SET_PAGE_SIZE,
  SPLIT_TESTS_LIST_SET_PAGE,
} from './consts.js';

const createInitialState = {
  sortModel: [{ field: 'creationDate', sort: 'desc' }],
  filterModel: undefined,
  pageSize: 50,
  page: 0,
};

export default (state = createInitialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case SPLIT_TESTS_LIST_SET_SORT_MODEL: {
      const { sortModel } = action;
      return {
        ...state,
        sortModel,
      };
    }
    case SPLIT_TESTS_LIST_SET_FILTER_MODEL: {
      const { filterModel } = action;
      return {
        ...state,
        filterModel,
      };
    }
    case SPLIT_TESTS_LIST_SET_PAGE_SIZE: {
      const { pageSize } = action;
      return {
        ...state,
        pageSize,
      };
    }
    case SPLIT_TESTS_LIST_SET_PAGE: {
      const { page } = action;
      return {
        ...state,
        page,
      };
    }
    default:
      return state;
  }
};
