import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { ButtonsGroupStyle, ButtonsGroupWrapper } from '../styles/form';
import { FlexContainer } from '../styles/common.js';
import { isNonEmptyString, isPositiveInteger } from '../utils/validators';

function dataIsValid({ slug, numberOfDistributions, usersPerDistribution, usersPerHour }) {
  return (
    isNonEmptyString(slug) &&
    isPositiveInteger(numberOfDistributions) &&
    isPositiveInteger(usersPerDistribution) &&
    (!usersPerHour || isPositiveInteger(usersPerHour))
  );
}

export default function SplitTestForm({
  onSubmit,
  gameSlug,
  slug: initSlug,
  numberOfDistributions: initNumberOfDistributions,
  usersPerDistribution: initUsersPerDistribution,
  usersPerHour: initUsersPerHour,
  isUpdate = false,
  disabled = false,
  enableEdit = false,
}) {
  const [slug, setSlug] = useState('');
  const [numberOfDistributions, setNumberOfDistributions] = useState(2);
  const [usersPerDistribution, setUsersPerDistribution] = useState(1);
  const [usersPerHour, setUsersPerHour] = useState(null);
  const [touched, setTouched] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const isUpdateActionAllowed =
    initNumberOfDistributions !== numberOfDistributions ||
    initUsersPerDistribution !== usersPerDistribution ||
    initUsersPerHour !== usersPerHour;

  const isActionDisabled = isSubmitDisabled || disabled || (!isUpdateActionAllowed && isUpdate);

  useEffect(() => {
    setIsSubmitDisabled(
      !dataIsValid({ slug, numberOfDistributions, usersPerDistribution, usersPerHour })
    );
  }, [slug, numberOfDistributions, usersPerDistribution, usersPerHour]);

  useEffect(() => {
    if (!touched) {
      setSlug(initSlug);
      setNumberOfDistributions(initNumberOfDistributions);
      setUsersPerDistribution(initUsersPerDistribution);
      setUsersPerHour(initUsersPerHour);
    }
  }, [initSlug, initNumberOfDistributions, initUsersPerDistribution, initUsersPerHour, touched]);

  return (
    <FlexContainer>
      <TextField
        required
        disabled={isUpdate}
        onChange={(e) => {
          setSlug(e.target.value);
          setTouched(true);
        }}
        name="slug"
        value={slug}
        label="Split Test Slug"
        variant="outlined"
      />
      <TextField
        required
        disabled={disabled}
        type="number"
        onChange={(e) => {
          setUsersPerDistribution(Number(e.target.value));
          setTouched(true);
        }}
        name="usersPerDistribution"
        InputProps={{ inputProps: { min: 1 } }}
        value={usersPerDistribution.toString()}
        label="Users per distribution"
        variant="outlined"
      />
      <TextField
        required
        disabled={disabled}
        type="number"
        onChange={(e) => {
          setNumberOfDistributions(Number(e.target.value));
          setTouched(true);
        }}
        name="numberOfDistributions"
        InputProps={{ inputProps: { min: 1 } }}
        value={numberOfDistributions.toString()}
        label="Number of distribution"
        variant="outlined"
      />
      <TextField
        disabled={disabled}
        type="number"
        onChange={(e) => {
          setUsersPerHour(Number(e.target.value));
          setTouched(true);
        }}
        name="usersPerHour"
        InputProps={{ inputProps: { min: 1 } }}
        value={usersPerHour ? usersPerHour.toString() : ''}
        label="Limit users distributed per hour"
        variant="outlined"
      />
      <ButtonsGroupWrapper>
        <ButtonsGroupStyle>
          {enableEdit ? (
            <Button
              variant="contained"
              color="primary"
              component={NavLink}
              to={`/${gameSlug}/split-tests/${slug}`}
            >
              Edit
            </Button>
          ) : (
            <Button
              disabled={isActionDisabled}
              variant="contained"
              color="primary"
              onClick={() => {
                onSubmit({ slug, numberOfDistributions, usersPerDistribution, usersPerHour });
                setTouched(false);
              }}
            >
              {isUpdate ? 'Update' : 'Create'}
            </Button>
          )}
          <Button
            component={NavLink}
            to={`/${gameSlug}/split-tests`}
            key={gameSlug}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </ButtonsGroupStyle>
      </ButtonsGroupWrapper>
    </FlexContainer>
  );
}

SplitTestForm.defaultProps = {
  gameSlug: null,
  slug: '',
  numberOfDistributions: 2,
  usersPerDistribution: 1,
  usersPerHour: undefined,
  isUpdate: false,
  disabled: false,
  enableEdit: false,
};

SplitTestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  gameSlug: PropTypes.string,
  slug: PropTypes.string,
  numberOfDistributions: PropTypes.number,
  usersPerDistribution: PropTypes.number,
  usersPerHour: PropTypes.number,
  isUpdate: PropTypes.bool,
  disabled: PropTypes.bool,
  enableEdit: PropTypes.bool,
};
