import { styled } from '@mui/system';

export const ButtonsGroupStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: 200,
}));

export const ButtonsGroupWrapper = styled('div')(() => ({
  float: 'right',
}));
