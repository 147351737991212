import { executeHttpRequest } from '@softgames/use-google-identity';
import { InvalidationStatus } from '../enums';

const { REACT_APP_ST_SERVER } = process.env;

function getBaseUrl(gameSlug) {
  return REACT_APP_ST_SERVER.replace('#{GAME_SLUG}', gameSlug);
}

export async function getSplitTests(gameSlug) {
  const result = await executeHttpRequest({
    url: `${getBaseUrl(gameSlug)}/adminApi/v1/splitTests`,
    method: 'get',
    responseType: 'json',
    headers: {
      'sg-game-slug': gameSlug,
    },
  });
  return result.map(({ creationDate, ...rest }) => ({
    ...rest,
    creationDate: new Date(creationDate),
  }));
}

export async function getSplitTest({ slug, gameSlug }) {
  const result = await executeHttpRequest({
    url: `${getBaseUrl(gameSlug)}/adminApi/v1/splitTests/${slug}`,
    method: 'get',
    responseType: 'json',
    headers: {
      'sg-game-slug': gameSlug,
    },
  });
  if (!result) {
    return result;
  }
  const { creationDate, ...rest } = result;
  return {
    ...rest,
    creationDate: new Date(creationDate),
  };
}

export async function postSplitTest(splitTest) {
  return executeHttpRequest({
    url: `${getBaseUrl(splitTest.gameSlug)}/adminApi/v1/splitTests`,
    method: 'post',
    headers: {
      'sg-game-slug': splitTest.gameSlug,
    },
    body: splitTest,
  });
}

export async function patchSplitTest({ gameSlug, slug, patch }) {
  return executeHttpRequest({
    url: `${getBaseUrl(gameSlug)}/adminApi/v1/splitTests/${slug}`,
    method: 'patch',
    responseType: 'json',
    headers: {
      'sg-game-slug': gameSlug,
    },
    body: patch,
  });
}

export async function deleteSplitTest({ gameSlug, slug }) {
  return executeHttpRequest({
    url: `${getBaseUrl(gameSlug)}/adminApi/v1/splitTests/${slug}`,
    method: 'delete',
    responseType: 'json',
    headers: {
      'sg-game-slug': gameSlug,
    },
  });
}

export async function resetDistributedUsers({ gameSlug, slug }) {
  return executeHttpRequest({
    url: `${getBaseUrl(gameSlug)}/adminApi/v1/splitTests/${slug}/clear`,
    method: 'post',
    responseType: 'json',
    headers: {
      'sg-game-slug': gameSlug,
    },
  });
}

export async function getInvalidationStatus({ invalidationId, gameSlug }) {
  return executeHttpRequest({
    url: `${getBaseUrl(gameSlug)}/adminApi/v1/invalidations/${invalidationId}/status`,
    method: 'get',
    responseType: 'json',
    headers: {
      'sg-game-slug': gameSlug,
    },
  });
}

export async function isInvalidationInProgress({ invalidationId, gameSlug }) {
  const result = await getInvalidationStatus({ invalidationId, gameSlug });
  return result === InvalidationStatus.INPROGRESS;
}
