import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ open, splitTest, actionName, handleClose, dispatchActions }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {actionName} - {splitTest.slug}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to {actionName} - {splitTest.slug}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={dispatchActions} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  dispatchActions: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  actionName: PropTypes.string,
  splitTest: PropTypes.object,
};
