import { isEmpty, isString, isInteger, isFunction } from 'lodash';

export { isString, isInteger, isFunction };

export function isEmptyObject(obj) {
  return isEmpty(obj);
}

export function isEmptyArray(arr) {
  return isEmpty(arr);
}

export function isNonEmptyString(val) {
  if (!val) {
    return false;
  }
  if (!isString(val)) {
    return false;
  }
  return !!val.trim();
}

export function isPositiveInteger(val) {
  if (!val) {
    return false;
  }
  if (!isInteger(val)) {
    return false;
  }
  return val > 0;
}

export function isNonNegativeInteger(val) {
  if (val == null) {
    return false;
  }
  if (!isInteger(val)) {
    return false;
  }
  return val > -1;
}

export function isNonNegativeIntegerLike(val) {
  if (val == null) {
    return false;
  }
  return isNonNegativeInteger(Number(val));
}

export function isTruthy(val) {
  if (!val) {
    return false;
  }
  return ['1', 'true'].includes(String(val).trim().toLowerCase());
}

export function hasOwnProperty(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
