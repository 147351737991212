import {
  APP_SET_USER,
  APP_SET_THEME_MODE,
  APP_ADD_MESSAGE,
  APP_DELETE_MESSAGE,
  APP_SET_LOADING,
  APP_SET_GAMES,
  APP_SET_ERROR,
  APP_ADD_INVALIDATION,
  APP_DELETE_INVALIDATION,
} from './consts';
import { getSplitTestGames } from '../../connections/sis';
import { isInvalidationInProgress } from '../../connections/st-api';
import { Severity } from '../../enums';
import { getLog } from '../../utils/log';

const log = getLog('App:action');

export function setUser(user) {
  return {
    type: APP_SET_USER,
    user,
  };
}

export function setThemeMode(mode) {
  return {
    type: APP_SET_THEME_MODE,
    mode,
  };
}

export function addMessage(messageId, severity, text) {
  return { type: APP_ADD_MESSAGE, messageId, severity, text };
}

export function removeMessage(messageId) {
  return { type: APP_DELETE_MESSAGE, messageId };
}

export function setLoading(status) {
  return { type: APP_SET_LOADING, status };
}

export function setGames(games) {
  return { type: APP_SET_GAMES, games };
}

export function setError(error) {
  return { type: APP_SET_ERROR, error };
}

export function addInvalidation(invalidationId, gameSlug, slug) {
  return { type: APP_ADD_INVALIDATION, invalidationId, gameSlug, slug };
}

export function deleteInvalidation(invalidationId, gameSlug, slug) {
  return { type: APP_DELETE_INVALIDATION, invalidationId, gameSlug, slug };
}

export function loadGames() {
  return (dispatch) => {
    dispatch(setLoading(true));
    getSplitTestGames()
      .then((games) => {
        dispatch(setGames(games));
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  };
}

function dispatchDeleteInvalidation({ dispatch, invalidationId, gameSlug, slug, error }) {
  const severity = error ? Severity.ERROR : Severity.SUCCESS;
  const text = error
    ? `Failed invalidate for Split Test ${slug} for game ${gameSlug}. Error message: ${error.message}`
    : `Invalidation  for Split Test ${slug} for game ${gameSlug}`;
  dispatch(deleteInvalidation(invalidationId, gameSlug, slug));
  dispatch(addMessage(`invalidation-${slug}-${gameSlug}`, severity, text));
}

export function checkInvalidationStatus({ invalidationId, gameSlug, slug }) {
  return (dispatch) => {
    dispatch(addInvalidation(invalidationId, gameSlug, slug));
    isInvalidationInProgress({
      invalidationId,
      gameSlug,
    })
      .then((invalidationStatus) => {
        if (!invalidationStatus) {
          dispatchDeleteInvalidation({ dispatch, invalidationId, gameSlug, slug });
        } else
          setTimeout(() => {
            dispatch(checkInvalidationStatus({ invalidationId, gameSlug, slug }));
          }, 3000);
      })
      .catch((error) => {
        dispatchDeleteInvalidation({ dispatch, invalidationId, gameSlug, slug, error });
        log.error(
          'Failed to check current invalidation status, BUT presumably invalidation is done.',
          error
        );
      });
  };
}
